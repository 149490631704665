@import "../variables";

.main-sidebar {
  &.side-menu {
    width: $sidebar-width !important;
    padding: 1rem 0;
    z-index: 6;
    overflow: hidden;
    height: 100vh;
  }

  &.main-sidebar-sticky {
    background: $white;
    box-shadow: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    overflow-y: auto;
    flex-shrink: 0;
    flex-direction: column;
    border-right: 1px solid $gray-100;
  }
}

.sidemenu-logo {
  width: $sidebar-width;
  padding-bottom: 12px;
  border-bottom: 1px solid $gray-100;

  .main-logo {
    padding-left: 20px;
  }
}

.main-sidebar-body {
  display: flex;
  width: $sidebar-width;
  max-height: 59%;
  padding: 0 0.5rem;
  margin-top: 10px;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  scrollbar-width: none;
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: content-box;
  border-bottom: 1px solid $gray-100;

  .nav {
    margin: 10px 0;

    &-link {
      margin: 10px 0;
      color: $gray-700;

      &.show,
      &:hover {
        color: $gray-500;
      }
    }

    &-link,
    &-submenu-link,
    &-link.active-parent-menu {
      padding: 12px 16px;
    }
  }
}

.nav {
  min-width: calc($sidebar-width - 1.25em);
  flex-direction: column;

  &-divider {
    border-bottom: 1px solid $gray-100;
  }

  &.show + &-submenu.hide {
    max-height: 0;
    transition: all 0.75s;
  }

  /*nav-links style*/
  li.nav-item {
    cursor: pointer;

    &.active {
      .nav-link,
      .nav-submenu-link {
        color: $white;
        background-color: $primary-400;
        position: relative;
        border: 0 solid transparent;
        width: inherit;
        height: inherit;
        border-radius: 4px;
        margin: 0;
      }
    }
  }

  &-link {
    color: $gray-700;

    &.show,
    &:hover {
      color: $gray-300;
    }

    img {
      margin-right: 0.875rem;
      margin-top: -0.15rem;
    }

    &.show + .nav-submenu.show {
      max-height: 100vh;
      transition: all 2s;
    }
  }

  &-submenu {
    max-height: 0;
    overflow: hidden;
    transition: all 0.75s;

    &-link {
      color: $gray-600;
      padding-left: 3.25rem !important;
    }
  }

  &-link,
  &-submenu-link,
  &-link.active-parent-menu {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: -0.005em;
    text-align: left;

    &.show,
    &:hover {
      color: $gray-500;
    }
  }
}

.main-sidebar-footer {
  background-color: white;
  padding: 0 0.5rem;
  position: absolute;
  bottom: 1rem;
  overflow: hidden;
  border: none;
  z-index: 7;

  .nav {
    &-link,
    &-submenu-link {
      color: $gray-700;
      margin: 0;
      line-height: 15px;
      font-size: 13px;
    }

    &-link,
    &-submenu-link,
    &-link.active-parent-menu {
      padding: 12px 16px;
    }
  }

  .main-profile-menu {
    h6 {
      margin-top: 1px;
      font-size: 16px;

      &::after {
        content: " ";
        height: 10px;
        width: 10px;
        position: absolute;
        bottom: 0;
        right: 0;
        border-radius: 50%;
        background-color: $success-600;
      }
    }
  }

  .profile {
    display: flex;
    margin: 25px 5px 0 0;

    &-info {
      margin: 0 12px;
      width: 9.25rem;
    }
  }
}

@media (max-width: 992px) {
  .main-sidebar {
    position: fixed;

    &.side-menu {
      z-index: 5;
      .sidemenu-logo {
        border-bottom: none;
      }
    }
  }
  .main-sidebar-footer {
    .profile {
      &-info {
        margin: 0 12px;
        width: 9xrem;
      }
    }
  }
}

@media (max-height: 550px) {
  .main-sidebar-body {
    .nav {
      &-link {
        margin: 5px 0;
      }
      &-link,
      &-submenu-link,
      &-link.active-parent-menu {
        padding: 10px 16px;
      }
    }
  }
  .main-sidebar-footer {
    .nav {
      &-link,
      &-submenu-link,
      &-link.active-parent-menu {
        padding: 8.35px 16px;
      }
    }
  }
}