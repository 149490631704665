@import "../variables";

/* ACCORDION */
.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 16px;
  font-weight: 700;
  line-height: 20.16px;
  text-align: left;
  background-color: #F3F4FB;
  border: 0;
  border-radius: 5px;
  overflow-anchor: none;

  &:focus, &:active {
    z-index: 3;
    border-color: transparent;
    outline: 0;
    box-shadow: transparent;
  }

  &:first-child {
    border-top: 0.3px solid $primary-400;
  }

  &:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: transparent;
    box-shadow: none;
  }
}

.accordion-item {
  outline: none;
  margin-bottom: 15px;
  background-color: #F3F4FB;
  border-radius: 5px;

  &:not(.collapsed) {
    border-bottom: 0.3px solid $primary-400;
    border-left: 0.3px solid $primary-400;
    border-right: 0.3px solid $primary-400;
  }
}


/* BUTTON */
.btn {
  height: 50px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  user-select: none;
  padding: 8px 18px;
  background-color: transparent;
  border: 1px solid transparent;
  margin: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-sm, .btn-group-sm > .btn {
  height: 36px;
  padding: 5px 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
}

.btn-block {
  display: block;
  width: 100%;
}

[class*=btn-outline-] {
  border-width: 1px;
  background-color: transparent;

  &:hover, &:focus {
    color: $white;
  }
}


.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  background-color: inherit;
  border-color: inherit;

  &:hover, &:focus, &:active {
    background-color: inherit;
    border-color: inherit;
  }
}

.btn-link, .btn.show {
  border-color: transparent !important;

  &:hover, &:focus, &:active, &.focus {
      color: transparent;
      background-color: transparent;
      border-color: transparent !important;
    }
}

.btn-primary {
  color: $white;
  background-color: $primary;
  border-color: $primary;
  @extend .box-shadow;

  &:hover, &:focus, &.focus {
    color: $white;
    background-color: lighten($primary, 7.5%);
    border-color: lighten($primary, 7.5%);
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 1px lighten($primary, 7.5%);
  }

  &.disabled, &:disabled {
    color: $white;
    background-color: $primary-500;
    border-color: $primary-500;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: lighten($primary, 7.5%);
      border-color: $primary;
    }
  }
}

.btn-outline-primary {
  color: $primary !important;
  border-color: $primary;
  @extend .box-shadow;

  &:hover {
    background-color: lighten($primary, 55%);
    border-color: $primary;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 1px lighten($primary, 55%);
  }

  &.disabled, &:disabled {
    color: $primary-500;
    border-color: $primary-500;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      background-color: transparent;
      border-color: $primary;
    }
  }
}

.btn-danger {
  color: $white;
  background-color: $danger-700;
  border-color: $danger-700;
  @extend .box-shadow;

  &:hover, &:focus, &.focus {
    color: $white;
    background-color: lighten($danger-700, 7.5%);
    border-color: lighten($danger-700, 7.5%);
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 1px lighten($danger-700, 7.5%);
  }

  &.disabled, &:disabled {
    color: $white;
    background-color: $danger-700;
    border-color: $danger-700;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $white;
      background-color: lighten($danger-700, 7.5%);
      border-color: $danger-700;
    }
  }
}

.btn-outline-danger {
  color: $danger-700 !important;
  border-color: $danger-700;
  @extend .box-shadow;

  &:hover {
    background-color: lighten($danger-700, 55%);
    border-color: $danger-700;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 1px lighten($danger-700, 55%);
  }

  &.disabled, &:disabled {
    color: $danger-700;
    border-color: $danger-700;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      background-color: transparent;
      border-color: $danger-700;
    }
  }
}

.btn-info-light {
  color: $info-400;
  background-color: $info-75;
  border-color: $info-75;
  @extend .box-shadow;

  &:hover, &:focus, &.focus {
    color: $info-400;
    background-color: lighten($info-75, 3.5%);
    border-color: lighten($info-75, 3.5%);
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 1px lighten($info-75, 3.5%);
  }

  &.disabled, &:disabled {
    color: $info-300;
    background-color: lighten($info-50, 3.5%);
    border-color: lighten($info-50, 3.5%);
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $info-400;
      background-color: lighten($info-75, 3.5%);
      border-color: $info-75;
    }
  }
}

.btn-outline-gray {
  color: $gray-700 !important;
  border-color: $gray-300;
  @extend .box-shadow;

  &:hover {
    background-color: $gray-50;
    border-color: $gray-400;
  }

  &:focus, &.focus {
    box-shadow: 0 0 0 1px $gray-50;
  }

  &.disabled, &:disabled {
    color: $gray-500;
    background-color: $gray-50;
    border-color: $gray-200;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      background-color: transparent;
      border-color: $gray-700;
    }
  }
}

.btn-flat-gray {
  color: $black-2 !important;
  font-weight: 600;
  border: none !important;

  &:hover {
    color: $black-2 !important;
    font-weight: 600;
    border: none !important;
  }

  &:focus, &.focus {
    text-shadow: 0 0 0 2px lighten($black-2, 40);
  }

  &.disabled, &:disabled {
    color: $gray-400 !important;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled) {
    &:active, &.active {
      color: $black-2 !important;
      font-weight: 600;
      border: none !important;
    }
  }
}


@media (max-width: 576px) {
  .btn, .btn-sm, .btn-group-sm > .btn {
    padding: 5px 8px;
    font-size: 13px;
  }
}


