@import "../variables";


.form-lightblue {
  color: #828282 !important;
  @extend .bg-primary-lighter;

  &:disabled {
    color: $black-2 !important;
    @extend .bg-primary-light
  }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
}

.form-floating {
  label {
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0;
    text-align: left;
    color: $gray-800;
  }
}

.form-floating>.form-control:focus~label::after,
.form-floating>.form-control:not(:placeholder-shown)~label::after,
.form-floating>.form-control-plaintext~label::after,
.form-floating>.form-select~label::after {
  background-color: transparent;
}

.react-datepicker {
  .react-datepicker__input-container {
    input {
      padding: 15px 10px 0 12px;
    }

  }

  >label {
    top: -10px;
    font-size: 11px !important;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0;
    color: $gray-600;
    left: 0;
    z-index: 2;
    height: 100%;
    padding: 1rem 0.75rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: var(--bs-border-width) solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  }
}

.form-floating.react-datepicker {
  width: 100%;
}

.form-control {
  width: 100%;
  font-size: 0.875rem;
  font-weight: 550;
  font-style: normal;
  line-height: 1.26875rem;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid $gray-5;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 5px;
  color: $gray-800;
  height: 58px;
  padding: 20px 32px;
  align-items: center;
  align-self: stretch;

  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  &:focus {
    outline: 0;
    border-color: $primary-200;
    box-shadow: none;
  }

  &::placeholder {
    color: $gray-400;
    opacity: 1;
  }

  &:disabled,
  &[readonly] {
    opacity: 1;
    background-color: $gray-100;
    color: $gray-500;
  }

  .is-invalid {
    border-color: $danger !important;
    &:focus {
      border-color: $danger !important;
      box-shadow: 0 0 0 3px rgba(var($danger), 0.25) !important;
    }
  }
}

.form-control-sm {
  height: 36px !important;
  padding: 0 16px 0 36px !important;
}

.filter-control {
  width: 400px;
}

.input-group-text {
  height: 58px;
}

.input-group-text-sm {
  height: 36px !important;
}

.form-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.custom-radio {
  width: 20px; 
  height: 20px;
  accent-color: $primary;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media (max-width: 480px) {
  .filter-control {
    width: 300px;
  }
}

@media (max-width: 370px) {
  .filter-control {
    width: 200px;
  }
}