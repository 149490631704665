@import "../variables";

.nav-underline {
  margin-bottom: 30px;
  border-bottom: 1px solid $gray-200;

  .nav-item {

    .nav-link,
    button {
      display: block;
      padding: 16px;
      color: $gray-700;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.26875rem;
      text-decoration: none;
      background: none;
      border: 0;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

      &:hover,
      &:active,
      &:focus,
      &.active {
        color: $primary;
        border-bottom: 1px solid $primary;
      }
    }
  }
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

@media (max-width: 575px) {
  .nav-underline {
    .nav-item {
      display: block;
      margin: 0 auto;
    }
  }
}