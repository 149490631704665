@import "../variables";

.cursor-pointer {
  cursor: pointer;
}

.box-shadow{
    box-shadow: 0 5px 3px -2px rgba(0, 0, 0, 0.02), 0px 3px 2px -2px rgba(0, 0, 0, 0.06);
}

.pe-2rem {
  padding-right: 2.25rem !important;
}

.mb-30px{
  margin-bottom: 30px;
}

.w-450{
  width: 450px;
}

.fs-12px {
  font-size: 12px;
}

@media (max-width: 465px) {
  .w-450{
    width: auto;
  }
}