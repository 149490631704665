@import "../variables";

.dropdown-toggle::after,
.dropdown-toggle::before {
  display: none !important;
}

.header-dropdown {
  .dropdown-menu {
    min-width: 265px;
    max-height: 350px;
    overflow-y: scroll;
    @extend .top-left;
    @extend .border;
    @extend .box-shadow;

    .dropdown-item {
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }
  }
}

.dropdown-menu {
  padding: 7px 16.52px 0;
  border: none !important;
  border-radius: 8.82px;
}

.dropdown-item {
  padding: 7px 5px;
  margin: 0;
}

.dropdown-divider {
  @extend .border-gray-100;
}

.custom-dropdown {
  width: 100%;
  position: absolute;
  margin-bottom: 5px;
  @extend .border;
  @extend .box-shadow;

  &-item {
    font-size: 18px;
    padding: 15px;
    font-weight: 300;
    line-height: 25px;
    white-space: normal !important;
    @extend .border-bottom;

    &:last-child {
      border: none !important;
    }
  }
}


.tableMoreDropdown {
  position: absolute;
  @extend .center-center;

  .dropdown-menu {
    position: absolute;
    background: $white;
    top: 5px !important;
    border-radius: 0.5rem;
    @extend .border;
    @extend .box-shadow;
    @extend .border-gray-100;

    .dropdown-item {
      padding-top: 0.325rem;
      padding-bottom: 0.325rem;
    }
  }

  .btn {
    padding: 8px;
    border-radius: 8px;
    border: 1px solid $gray-200;

    &:hover {
      background: $gray-50;
    }

    &:focus,
    &.focus {
      border: 1px solid $secondary-300;
    }
  }
}

@media (max-width: 500px) {
  .header-dropdown {
    .dropdown-item {
      white-space: normal !important;
    }
  }
}