@import "../variables";

.main-header, .mobile-main-header {
  height: $header-height;
  background-color: $white;
  z-index: 5;
  width: 100%;

  > {
    .container-fluid {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  input.form-control {
    min-width: 450px;
    height: 40px;
  }
}

.main-header-left {
  display: flex;
  align-items: center;
  margin-left: $sidebar-width;
}

.main-header-right {
  display: flex;
  align-items: center;
}


@media (max-width: 992px) {
  .main-header, .mobile-main-header {
    z-index: 6 !important;
    border-bottom: 0.3px solid $gray-300;
  }
  .main-header-left {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .main-header {
    input.form-control {
      max-width: 350px;
    }
  }
}
