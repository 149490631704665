$sidebar-width: 19em;
$header-height: 3.85em;

/*Color variables*/
$primary: #2C3891;
$secondary: #3C91E6;
$info: #FFAB00;
$medium: #7C7C7C;
$success: #0F973D;
$warning: #ff9b21;
$danger: #CB1A14;
$dark: #3b4863;
$light: #E8E8E8;
$black: #000000;
$white: #ffffff;

/*neutral variables*/
$neutral-200: #E5E7EB;
$black-5: #333333;
$black-4: #4F4F4F;
$black-3: #828282;
$black-2: #808080;

/*gray variables*/
$gray-900: #101928;
$gray-800: #1D2739;
$gray-700: #344054;
$gray-600: #475367;
$gray-500: #667185;
$gray-400: #98A2B3;
$gray-300: #D0D5DD;
$gray-200: #E4E7EC;
$gray-100: #F0F2F5;
$gray-75: #F7F9FC;
$gray-50: #F9FAFB;
$gray-5: #E0E0E0;

/*primary variables*/
$primary-900: #0f1330;
$primary-800: #161c49;
$primary-700: #1d2561;
$primary-600: #252f79;
$primary-500: #4f59a3;
$primary-400: #727ab6;
$primary-200: #969cc8;
$primary-100: #b9bdda;
$primary-50: #d5d7e9;
$primary-5: #e7e8f5;

/*secondary  variables*/
$secondary-900: #14304d;
$secondary-800: #1e4873;
$secondary-700: #286099;
$secondary-600: #3278c0;
$secondary-500: #5da3ea;
$secondary-400: #7db5ee;
$secondary-300: #9ec8f3;
$secondary-200: #bedaf7;
$secondary-100: #d8e9fa;

/*Office brown variable*/
$office-brown-800: #514A4A;
$office-brown-700: #645D5D;
$office-brown-600: #A29999;


/*warning  variables*/
$info-900: #332200;
$info-800: #553900;
$info-700: #805600;
$info-600: #aa7200;
$info-500: #d58f00;
$info-400: #D48E00;
$info-300: #ffb92a;
$info-200: #ffc755;
$info-100: #ffd580;
$info-75: #ffe3aa;
$info-50: #ffeecc;

/*success variables*/
$success-700: #036B26;
$success-600: #04802E;
$success-500: #099137;
$success-400: #0F973D;
$success-300: #34A853;
$success-200: #5FC381;
$success-100: #D8F3DF;
$success-50: #E7F6EC;

/*danger variables*/
$danger-900: #AD3307;
$danger-700: #E82E2E;
$danger-600: #E8562E;
$danger-50: #FFECE5;

