@import "../variables";


.fw-500{
  font-weight: 500 !important;
}

.text-13px{
  font-size: 12.8px !important;
}

.text-white {
  color: $white;
}

.text-primary{
  color: $primary !important;
}

.text-gray-900 {
  color: $gray-900;
}

.text-gray-800 {
  color: $gray-800;
}

.text-gray-700 {
  color: $gray-700;
}

.text-gray-500 {
  color: $gray-500;
}

.text-gray-600 {
  color: $gray-600;
}

.text-gray-400 {
  color: $gray-400;
}

.text-gray-300 {
  color: $gray-300;
}

.text-gray-200 {
  color: $gray-200;
}

.text-gray-100 {
  color: $gray-100;
}

.text-gray-75 {
  color: $gray-75;
}

.text-black-5 {
  color: $black-5;
}

.text-black-4 {
  color: $black-4;
}

.text-black-3 {
  color: $black-3;
}

.text-black-2 {
  color: $black-2;
}

.text-info{
  color: $info-400 !important;
}

.text-danger{
  color: $danger !important;
}

.text-success{
  color: $success-500 !important;
}

.text-danger-900 {
  color: $danger-700 !important;
}

.text-danger-600{
  color: $danger-600;
}

.text-office-brown-800{
  color: $office-brown-800;
}

.text-office-brown-700{
  color: $office-brown-700;
}