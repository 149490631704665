@import "../variables";

.card {
  border-radius: 10px;
  padding: 10px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  background-color: $white;
  background-clip: border-box;
  border: 1px solid $gray-50;
  box-shadow: 0 2px 4px -1px rgba(16, 25, 40, 0.02), 0px 5px 13px -5px rgba(16, 25, 40, 0.05);
  align-items: flex-start;
  gap: 1.25rem;
}

@media (max-width: 576px) {
  .card {
    padding: 10px;
  }
  .custom-card {
    min-height: 60vh;
  }
}

.card-body {
  flex: 1 1 auto;
  width: 100% !important;
}
