@import "../variables";

.pagination-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 0;
  list-style: none;
  overflow-x: auto;
  overflow-y: hidden;
}

.pagination-info {
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  font-weight: 700;
  white-space: nowrap;
}

.nav-pages,
.nav-action {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;
}

.nav-pages .page-link {
  border: none;
  padding: 5px 8px;
  margin-top: 12px;
}

.nav-pages li {
  a {
    color: $gray-500;
    border-radius: 6px;
    font-weight: 700;
    border: none;
    &:focus, &:hover {
      background-color: #D8DBF3;
      color:  #2D3892;
    }
  }
}

.nav-pages li.active a{
        background-color: #D8DBF3;
        color:  #2D3892;
        border: none;
}
.nav-action {
  button {
    font-weight: 700;
    font-size: 14px;
  }

  img {
    margin-bottom: 2px;
    padding: 0 2px;
  }
}

@media (max-width: 576px) {
  .nav-pages{
    margin-right: 20px;
  }
  
  .nav-action {
    img {
      display: none;
    }
  }
}