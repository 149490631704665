@import "variables";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/bootstrap";
@import "~react-datepicker/dist/react-datepicker.css";

@import "colors";

@import "bootstrap/badges";
@import "bootstrap/bootstrap-custom";
@import "bootstrap/buttons";
@import "bootstrap/cards";
@import "bootstrap/dropdown";
@import "bootstrap/forms";
@import "bootstrap/modals";
@import "bootstrap/nav";
@import "bootstrap/pagination";
@import "bootstrap/tables";

@import "layouts/header";
@import "layouts/loader";
@import "layouts/sidemenu";
@import "layouts/main-content";
@import "layouts/tree";
@import "layouts/auth";

@import "icons/materialicons";

@import "utils/border";
@import "utils/position";
@import "utils/typography";

@import "custom/custom-styles";
@import "custom/custom-utils";

