@import "../variables";

.table-responsive {
  display: block;
  width: 100%;
  background: $white;
  overflow-x: auto;
  border-radius: 10px 10px 0 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar{
    display: none;
  }

  > .table-bordered {
    border: 0;
  }
}



thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: none;
  border-width: 0;
}

.table {
  width: 100%;
  margin-bottom: 0;
  border-collapse: collapse;
  position: relative;

  th, td {
    padding: 16px 24px;
    gap: 12px;
    vertical-align: middle;
    height: 52px;
    line-height: 1.462;
  }

  thead th {
    vertical-align: bottom;
    border-bottom: 0 !important;
    height: 45px;
  }

  thead th:first-child {
    border-radius: 10px 0 0 0;
  }

  thead th:last-child {
    border-radius: 0 10px 0 0;
  }

  tbody + tbody {
    border-top: 1px solid $gray-75;
  }

  thead {
    border-bottom-width: 0;
    border-top-width: 0;

    th, td {
      color: $gray-700;
      font-style: normal;
      background-color: $gray-50;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0;
      text-align: left;
    }
  }

  tbody tr {
    height: 52px;
    border-top: 1px solid $neutral-200;

    td {
      color: $gray-700;
      max-width: 250px;
      @extend .text-truncate;
    }
  }
}



