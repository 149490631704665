@import "../variables";

.badge {
  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: 20px;
  display: inline-block;
  padding: 2px 12px 3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: fit-content;
  height: fit-content;
  border-radius: 12px;
  white-space: nowrap;
  vertical-align: baseline;
  letter-spacing: 0;

  &-success {
    color: $success-300;
    background: $success-100;
  }

  &-danger {
    color: $danger-900;
    background: $danger-50;
  }

  &-info {
    color: $info-400;
    background: $info-50;
  }

  &-primary{
    color: $primary-600;
    background: $primary-5;
  }
}

