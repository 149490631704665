@import "../variables";

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

.main-content {
  &.side-content {
    margin-left: $sidebar-width;
    position: relative;
    transition: none;
    height: 100%;
    padding: 0;
    bottom: 0;
    border-radius: 0;
    overflow-x: hidden;
  }

  .container, .container-fluid {
    padding: 36px;
  }

  .inner-body {
    margin-top: 70px;
  }
}

.main-profile-menu {
  position: relative;
  padding: 0.65rem 0;
  margin: 0;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 12.5rem;

  > img {
    outline: none;
    width: 20px;
    height: 20px;
    margin: 0 auto;
    display: block;
    border-radius: 100%;
  }
}

.profile-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 120px;
  height: 120px;

  > img{
    width: 120px;
    height: 120px;
  }
}

@media (max-width: 992px) {
  .main-content {
    &.side-content {
      margin-left: 0;
    }
  }
}

@media (max-width: 768px) {
  .main-content {
    .container, .container-fluid {
      padding: 15px;
    }
  }
  .profile-avatar{
    width: 100px;
    height: 100px;
  }
}