.bg-light-orange{
  background: #FFECE5 !important;
}

.bg-primary-light{
  background: #D8DBF3 !important;
}

.bg-primary-lighter{
  background-color: #EBEDF9 !important;
}

.bg-dark{
  background: #232323 !important;
}

.bg-light{
  background: #f8f9fa !important;
}
