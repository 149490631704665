@import "../variables";

.border-none{
  border: none !important;
}

.border {
  border: 1px solid $gray-200 !important;
}

.border-top {
  border-top: 1px solid $gray-200 !important;
}

.border-end {
  border-right: 1px solid $gray-200 !important;
}

.border-bottom {
  border-bottom: 1px solid $gray-200 !important;
}

.border-start {
  border-left: 1px solid $gray-200 !important;
}

.border-radius-5{
  border-radius: 5px;
}

.border-primary {
  border-color: $primary !important;
}

.border-primary-light{
  border-color: lighten($primary, 50%) !important;
}

.border-gray-100{
  border-color: $gray-100 !important;
}
