@import "../variables";

.main-body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.custom-line-through {
  small {
    display: flex;
    flex-direction: row;
    margin: 1.2rem 0;

    &:before,
    &:after {
      content: "";
      flex: 1 1;
      border-bottom: 1px solid $gray-200;
      margin: auto;
    }

    &:before {
      margin-right: 5px;
    }

    &:after {
      margin-left: 5px;
    }
  }
}

.info-group {
  width: 100%;
  padding-bottom: 1rem;

  & > div {
    display: flex;
    justify-content: space-between;
    column-gap: 2rem;
    padding: 17px 0;

    p {
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      white-space: normal !important;
    }
  }
}

@media (max-width: 500px) {
  .info-group {
    & > div {
      p {
        font-size: 15px;
        line-height: 20px;
      }
    }
  }
}

.error-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .error-text {
    font-size: 20px;
    line-height: 29px;
    font-weight: 500;
    color: $gray-500;
    text-align: center;
    max-width: 800px;
    margin: 10px;
  }

  .error-title {
    font-size: 50px;
    font-weight: 700;
    line-height: 45px;
    text-align: center;
    color: $primary;
  }

  @media (max-width: 576px) {
    .error-title {
      font-size: 35px;
    }
    .error-text {
      font-size: 14px;
      line-height: 19px;
    }
  }
}

.task-list {
  list-style: none;
  position: relative;
  margin-top: 40px;
  padding: 0;
  color: #3c4858;
  clear: both;

  &:after {
    content: "";
    position: absolute;
    background: #808080;
    height: 90%;
    width: 0.3px;
    top: 20px;
    left: 10px;
    z-index: 1;
  }

  li {
    margin-bottom: 70px;
    padding-left: 45px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    > .task-icon {
      content: "";
      position: absolute;
      left: -1px;
      top: 0;
      width: 24px;
      height: 24px;
      z-index: 2;
      padding: 3px 5px;
      border-radius: 50%;
      background: $white;
      border: 2px solid $info-400;

      &.active {
        border: 2px solid #87aba9;
        color: #005e60 !important;
      }
    }
    small {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0;
      text-align: right;
    }
  }
}

.line {
  width: 120px;
  height: 3px;
}

.map {
  height: 650px;
  width: 100%;
  border-radius: 8px;
}

.carousel-control-prev-icon, .carousel-control-next-icon, .carousel-indicators [data-bs-target]  {
  display: none;
}

@media (max-width: 550px) {
  .map {
    height: 450px;
  }
}
