@import "../variables";


:root {
  --primary-bg-color: #2D3892;
  --primary-bg-hover: #2b3691;
  --primary-transparentcolor: rgba(45, 56, 146, 0.16);
  --primary-bg-border: #2D3892;
  --dark-theme: #0e0e23;
  --dark-body: #24243e;
  --dark-border: rgba(255, 255, 255, 0.15);
  --dark-color: #d0d0e2;
  --dark-primary: #6259ca;
  --indigo: #4b0082;
  --purple: #6f42c1;
  --pink: #f1388b;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: $white;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #2D3892;
  --secondary: #3C91E6;
  --success: #0F973D;
  --info: #FFAB00;
  --warning: #ffc107;
  --danger: #CB1A14;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: 'Helvetica', "sans-serif", serif, "Material-Design-Iconic-Font";
  --font-family-monospace: 'Helvetica', "sans-serif", serif, "Material-Design-Iconic-Font";
  --bs-heading-color: $gray-900;
}


* {
  box-sizing: border-box;
  font-family:  'Helvetica', "sans-serif", serif, "Material-Design-Iconic-Font" !important;

  &::before, &::after {
    box-sizing: border-box;
  }
}

html {
  font-family:  'Helvetica', "sans-serif", serif , "Material-Design-Iconic-Font";
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  margin: 0;
  padding: 0;
  font-family:  'Helvetica', "sans-serif", serif, "Material-Design-Iconic-Font" !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.26875;
  text-align: left;
  background: #F3F4FB;
  color: $gray-900;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-font-feature-settings: "liga" 0;
  font-feature-settings: "liga" 0;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-x: hidden;
  -ms-overflow-y: scroll;
  width: 100%;
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

//SCROLLBAR DESIGN
/* width */
::-webkit-scrollbar {
  width: 5px;
  background: transparent;
}

::-webkit-scrollbar-button{
  display: none;
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb, ::-webkit-scrollbar-button {
  border-radius: 10px;
  background: linear-gradient(180deg, $secondary-500 0%, $primary-500 99%);
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
  border: 1px solid $secondary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover, ::-webkit-scrollbar-button:hover {
  width: 3px;
  background: $primary-500;
}

a {
  font-style: normal;
  font-weight: 500;
  color: $primary;
  text-decoration: none;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  margin: 0;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-style: normal;
  font-weight: 700;
  margin: 0;
}

h1, .h1 {
  font-size: 2.25rem;
  line-height: 2.7rem;
  letter-spacing: -0.09rem;
}

h2, .h2 {
  font-size: 1.75rem;
  line-height: 2.1rem;
}

h3, .h3{
  font-size: 1.5rem;;
  line-height: 1.8rem;
}

h4, .h4 {
  font-size: 1.25rem;
  line-height: 1.5rem;
}

h5, .h5 {
  font-size: 1.125rem;
  line-height: 1.63125rem;
}

h6, .h6 {
  font-size: 1rem;
  line-height: 1.15rem;
}

input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

[type="number"] {
  &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
    height: auto;
  }
}

[hidden] {
  display: none !important;
}

img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
}

button {
  border-radius: 0;
  text-transform: none;

  &:focus {
    outline: none;
  }
}

@media (max-width: 500px) {
  body {
    font-size: 13px;
    line-height: 1.06875;
  }

  h1, .h1 {
    font-size: 2rem;
  }

  h2, .h2 {
    font-size: 1.5rem;
  }

  h3, .h3{
    font-size: 1.25rem;;
  }

  h4, .h4 {
    font-size: 1.125rem;
  }

  h5, .h5 {
    font-size: 1rem;
  }

  h6, .h6 {
    font-size: 0.85rem;
  }
}

