.sticky-pin {
  position: fixed !important;
  top: 0 !important;
  width: 100%;
}

.top-right {
  top: 16px;
  right: 16px;
}

.top-left {
  top: 16px !important;
  left: 5px;
}

.top-center {
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
}

.center-center {
  right: 0;
  transform: translate(-80%, -50%);
}

.bottom-left {
  bottom: 16px;
  left: 16px;
}

.bottom-center {
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
}

.bottom-right {
  bottom: 16px;
  right: 16px;
}

.middle-center {
  top: 36%;
  left: 45%;
  transform: translate-y(-50%);
}
